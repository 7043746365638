import React from 'react'

function Paragraph(props) {
    return (
        <div className="p-2">{props.children}</div>
    )
}

export default Paragraph

// TODO snippet for these kinds of components
